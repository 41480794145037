<template>
  <div class="center-table-main" ref="centerTableRef">
    <div class="left-tree">
      <el-input
          size="mini"
          placeholder="请输入设备名称"
          v-model="filterText">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <div class="screen-tree-main">
        <el-tree
            :props="props"
            :load="loadNode"
            ref="screenCenterTree"
            :filter-node-method="filterNode"
            lazy
            @node-click="handleNodeClick">
        </el-tree>
      </div>
    </div>
    <div class="right-table">
      <screen-table :data="useList" :height="height" v-if="height">
        <el-table-column
            prop="equidTerminal"
            label="终端ID"
            align="center">
        </el-table-column>
        <el-table-column
            prop="equipNo"
            align="center"
            label="设备编号">
        </el-table-column>
        <el-table-column
            prop="equipTypeName"
            label="设备类型"
            align="center">
        </el-table-column>
        <el-table-column
            prop="equipSource"
            align="center"
            label="设备来源">
        </el-table-column>
        <el-table-column
            prop="equipStatus"
            align="center"
            label="设备状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.equipStatus == 0" size="mini" type="success">在用</el-tag>
            <el-tag v-if="scope.row.equipStatus == 1" size="mini"  type="danger">停用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="equipModel"
            align="center"
            label="规格型号">
        </el-table-column>
        <el-table-column
            prop="equipProductDate"
            align="center"
            label="出厂日期">
        </el-table-column>
      </screen-table>
    </div>
  </div>
</template>

<script>
import ScreenTable from "./ScreenTable";
import common from "../../../api/modules/common";
import record from "../../../api/modules/record";

export default {
  name: "CenterTable",
  components: {ScreenTable},
  props:['id','type'],
  data() {
    return {
      props: {
        isLeaf: 'leaf'
      },
      height:null,
      useList: [],
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      activeType:''
    }
  },
  watch: {
    filterText(val) {
      this.$refs.screenCenterTree.filter(val);
    },
    id(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(newVal)
      }
    }
  },
  mounted() {
    this.height= this.$refs.centerTableRef.clientHeight - 10 + 'px'
  },
  methods:{
    handleNodeClick(val) {
      if(val.leaf){
        this.activeType = val.value
        this.getBasicInfo(this.id)
      }

    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    getBasicInfo(id){
      // 获取设备信息，默认只有前20条数据
      if(this.type ==3){
        let searchForm ={
          currPage: 1,
          pageSize: 20,
          equipTypeId: this.activeType  , // 设备类型
          orgaId: id
        }
        // 项目级
        record.getEquipmentListByPage(searchForm).then(res => {
          this.useList = res.data.list
        })
      }
    },
    loadNode(node, resolve) {
      const { level,data } = node;
      let id =  data && data.value? data.value :0
      common.getEquipmentList({equipTypeId:id}).then(res =>{
        if(res.code == 200 && res.data){
          let data = res.data.map(item =>{
            return {
              value: item.equipTypeId,
              label: item.equipTypeName,
              isSpecial: item.isSpecial,  // 1时为特种设备
              leaf: level >=1
            }
          })
          resolve(data);
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.center-table-main{
  background: url("../../../assets/images/screen/cnter-table-bg.png") no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: calc(100% - 46px);
  padding: 5px;
  .left-tree{
    width: 180px;
    float: left;
    height: 100%;
    background: #020F56;
    overflow: auto;
    .screen-tree-main{
      margin-top: 5px;

    }
  }
  .right-table{
    margin-left: 190px;
  }
}

</style>