<template>
  <div class="screen-table" >
    <el-table
        :data="data"
        :height="height"
        class="screen-table"
        align="center"
        row-class-name="screen-row"
        header-row-class-name="screen-header-row"
        style="width: 100%">
     <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "ScreenTable",
  props: ['data','height'],
  data(){
    return{
      // height: '20px'
    }
  },
  computed:{
  },
  mounted() {
    //
    console.log('this.height',this.height)
  }
}
</script>

<style scoped lang="less">
.screen-table{
  height: 100%;
  background: rgba(0,0,0,0);
}


</style>