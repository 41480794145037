<template>
  <div class="table-main" ref="screenUseTable">
    <screen-table :data="useList" :height="height" v-if="height">
      <el-table-column
          align="center"
          prop="typeName"
          label="名称">
        <template slot-scope="scope">
          <span class="click-line" @click="goDetail(scope.row,1)">{{scope.row.typeName}}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="equipNum"
          label="总数">
        <template slot-scope="scope">
          <span class="click-line" @click="goDetail(scope.row,1)">{{scope.row.equipNum}}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="useNum"
          label="使用数量">
        <template slot-scope="scope">
          <span class="click-line" @click="goDetail(scope.row,0)">{{scope.row.useNum}}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="useRate"
          label="使用率">
        <template slot-scope="scope">
          <span class="click-line" @click="goDetail(scope.row,0)">
            {{ scope.row.equipNum ? (Number(scope.row.useNum) / Number(scope.row.equipNum)).toFixed(4)*100 : '0.00'}} %
          </span>
        </template>
      </el-table-column>
    </screen-table>
  </div>

</template>

<script>
import ScreenTable from "./ScreenTable";
import screen from "../../../api/modules/screen";

export default {
  name: "EquipmentUse",
  components: {ScreenTable},
  props:['id','type','areaId'],
  watch:{
    id(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(newVal,this.areaId)
      }
    },
    areaId(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(this.id,newVal)
      }
    }
  },
  data() {
    return {
      height:null,
      useList: []
    }
  },
  created() {

  },
  mounted() {
    this.height= this.$refs.screenUseTable.clientHeight - 2 + 'px'
    if(this.id){
      this.getBasicInfo(this.id,this.areaId)
    }
  },
  methods:{
    getBasicInfo(id, areaId){
      let param ={}
      if(areaId){
        param = {orgaId:id,areaId:areaId}
      }else {
        param = {orgaId:id}
      }
      if(this.type ==1){
        // 局级
        screen.getOrgaUseState(param).then(res =>{
          this.useList = res.data
        })
      }else if(this.type ==2){
        // 公司级
        screen.getCompUseState(param).then(res =>{
          this.useList = res.data
        })
      }else {
        // 项目级
        screen.getProjUseState({orgaId:id}).then(res =>{
          this.useList = res.data
        })
      }
    },
    goDetail(row,status){
      let query ={}
      if(row.orgaPathIds){
        query.orgaPathIds = row.orgaPathIds
      }
      if(status ==0){
        // 在使用的
        query.equipState = 0
      }
      if(this.areaId){
        // 区域ID
        query.areaId = this.areaId
      }
      if(this.type ==3){
        // 项目级
        query.equipTypeId = row.typeId
      }
      this.$router.push({path:'/view/record/equipmentledger', query:query})
    }
  }
}
</script>

<style scoped lang="less">
.table-main{
  height: 100%;
}


</style>