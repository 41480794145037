import api from '../httpServer'
import config from '../config'

// 大屏统计的接口
const screen = {
    /*
    * 大屏接口
    * */

    //region 设备类型总数

    // 设备类型总数(公司)
    getCompEquipNum(data) {
        return api.get(`${config.headerUrl}bigScreen/getCompEquipNum`, data)
    },
    //设备类型总数(局级)
    getOrgaEquipNum(data) {
        return api.get(`${config.headerUrl}bigScreen/getOrgaEquipNum`, data)
    },
    // 设备类型总数(项目级)
    getProjEquipNum(data) {
        return api.get(`${config.headerUrl}bigScreen/getProjEquipNum`, data)
    },
    //endregion

    //region 设备预警
    //设备预警--统计(公司级)
    getCompEquipOverageStatic(data) {
        return api.get(`${config.headerUrl}bigScreen/getCompEquipOverageStatic`, data)
    },
    // 设备预警--统计(局级)
    getOrgaEquipOverageStatic(data) {
        return api.get(`${config.headerUrl}bigScreen/getOrgaEquipOverageStatic`, data)
    },
    // 设备预警--统计(项目级)
    getProjEquipOverageStatic(data) {
        return api.get(`${config.headerUrl}bigScreen/getProjEquipOverageStatic`, data)
    },
    //endregion

    //region 设备月检--统计
    // 设备月检--统计(公司级)
    getCompMothCheckStatic(data) {
        return api.get(`${config.headerUrl}bigScreen/getCompMothCheckStatic`, data)
    },
    // 设备月检--统计(局级)
    getOrgaMothCheckStatic(data) {
        return api.get(`${config.headerUrl}bigScreen/getOrgaMothCheckStatic`, data)
    },
    // 设备月检--统计(项目级)
    getProjMothCheckStatic(data) {
        return api.get(`${config.headerUrl}bigScreen/getProjMothCheckStatic`, data)
    },
    //endregion

    //region 人员信息
    // 人员信息(公司级)
    getCompOperatorInfo(data) {
        return api.get(`${config.headerUrl}bigScreen/getCompOperatorInfo`, data)
    },
    // 人员信息(局级)
    getOrgaOperatorInfo(data) {
        return api.get(`${config.headerUrl}bigScreen/getOrgaOperatorInfo`, data)
    },
    // 人员信息(项目级)
    getProjOperatorInfo(data) {
        return api.get(`${config.headerUrl}bigScreen/getProjOperatorInfo`, data)
    },
    //endregion

    //region 自有设备使用统计
    // 自有设备使用统计(公司级)
    getCompSelfEquipUseStatic(data) {
        return api.get(`${config.headerUrl}bigScreen/getCompSelfEquipUseStatic`, data)
    },
    // 自有设备使用统计(局级)
    getOrgaSelfEquipUseStatic(data) {
        return api.get(`${config.headerUrl}bigScreen/getOrgaSelfEquipUseStatic`, data)
    },
    // 自有设备--使用统计(项目级)
    getProjSelfEquipUseStatic(data) {
        return api.get(`${config.headerUrl}bigScreen/getProjSelfEquipUseStatic`, data)
    },
    //endregion

    //region 设备使用情况
    // 设备使用情况(公司)
    getCompUseState(data) {
        return api.get(`${config.headerUrl}bigScreen/getCompUseState`, data)
    },
    // 设备使用情况(局级)
    getOrgaUseState(data) {
        return api.get(`${config.headerUrl}bigScreen/getOrgaUseState`, data)
    },
    // 设备使用情况(项目级)
    getProjUseState(data) {
        return api.get(`${config.headerUrl}bigScreen/getProjUseState`, data)
    },
    //endregion

}

export default screen