<template>
  <div class="total-list-main">
    <div class="total-item" v-for="(item, index) in allList" :key="index" @click="goDetail(item)">
      <div class="item-img">
        <img :src="item.imgUrl">
      </div>
      <div class="item-title">
        <div class="name">{{item.name}}</div>
        <div class="num">{{item.total}}</div>
      </div>
    </div>

  </div>

</template>

<script>
import equip1 from '../../../assets/images/screen/equip_1.png'
import equip2 from '../../../assets/images/screen/equip_2.png'
import equip3 from '../../../assets/images/screen/equip_3.png'
import equip4 from '../../../assets/images/screen/equip_4.png'
import equip5 from '../../../assets/images/screen/equip_5.png'
import screen from "../../../api/modules/screen";

export default {
  name: "CenterTotalList",
  props:['id','type','areaId'],
  watch:{
    id(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(newVal,this.areaId)
      }
    },
    areaId(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(this.id,newVal)
      }
    }
  },
  data() {
    return {
      allList:[
        {
          type: 99,
          name:'塔式起重机',
          total: 0,
          imgUrl: equip1,
        }, {
          type: 108,
          name: '施工升降机',
          total: 0,
          imgUrl: equip2,
        }, {
          type: 97,
          name: '门式起重机',
          total: 0,
          imgUrl: equip3,
        }, {
          type: 66,
          name: '架桥机',
          total: 0,
          imgUrl: equip4,
        },
        {
          type: 210,
          name: '挂篮',
          total: 0,
          imgUrl: equip5,
        },

      ]
    }
  },
  mounted() {
    if(this.id){
      this.getBasicInfo(this.id)
    }
  },
  methods:{
    getBasicInfo(id,areaId){
      let param ={}
      if(areaId){
        param = {orgaId:id,areaId:areaId}
      }else {
        param = {orgaId:id}
      }
      if(this.type ==1){
        // 局级
        screen.getOrgaEquipNum(param).then(res =>{
          res.data.forEach(item =>{
            this.allList.forEach(all=>{
              if(item.typeId == all.type ){
                all.total = item.equipNum
              }
            })
          })

        })

      }else if(this.type ==2){
        // 公司级
        screen.getCompEquipNum(param).then(res =>{
          res.data.forEach(item =>{
            this.allList.forEach(all=>{
              if(item.typeId == all.type ){
                all.total = item.equipNum
              }
            })
          })
        })
      }else {
        // 项目级
        screen.getProjEquipNum({orgaId:id}).then(res =>{
          res.data.forEach(item =>{
            this.allList.forEach(all=>{
              if(item.typeId == all.type ){
                all.total = item.equipNum
              }
            })
          })
        })
      }
    },
    goDetail(row){
      let query ={}
      query.equipTypeId = row.type
      if(this.areaId){
        // 区域ID
        query.areaId = this.areaId
      }
      this.$router.push({path:'/view/record/acceptance', query:query})
    }
  }
}
</script>

<style scoped lang="less">
.total-list-main{
  width: 100%;
  display: flex;
  height: 100%;
  margin-top: 20px;
  .total-item{
    background: url("../../../assets/images/screen/center-bg.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    flex: 1;
    margin-left: 10px;
    padding: 20px;
    cursor: pointer;
    .item-img{
      height: 90px;
      line-height: 90px;
      line-height: 90px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        max-width: 100%;
        max-height: 100px;
      }
    }
    .item-title{
      margin-top: 10px;
      text-align: center;
      .name{
        font-size: 15px;
        //padding-right: 10px;
        position: relative;
        text-align: center;
      }
      .num{
        text-align: center;
        margin-top: 5px;
        color: #DF9A16;
        font-size: 22px;
        font-weight: 400;
        font-family: HuXiaoBoNanShenTi-2;
      }
    }
  }
}

</style>