
<template>
  <div class="person-char" ref="screenAlarmRef"></div>
</template>

<script>
import * as echarts from 'echarts'
import {screenEchartOption} from '../../../config/screenEchartConf'
import screen from "../../../api/modules/screen";

export default {
  name: "EquipmentAlarm",
  props:['id','type','areaId'],
  watch:{
    id(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(newVal,this.areaId)
      }
    },
    areaId(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(this.id,newVal)
      }
    }
  },
  data() {
    return {
      personEcharts: null
    }
  },
  mounted() {
    this.personEcharts = echarts.init(this.$refs.screenAlarmRef, "chalk");
  },
  methods: {
    getInfo(xAxis, overageCount) {
      let option = screenEchartOption
      // option.legend.data = ['超限设备数','报警次数', '检测到期数']
      option.legend.data = ['超限设备数']
      option.xAxis.data = xAxis
      option.series = [
        {
          name: '超限设备数',
          type: 'bar',
          data: overageCount,
          itemStyle:{
            barBorderRadius:10,
          },
          barMaxWidth: 12
        },
        // {
        //   name: '报警次数',
        //   type: 'bar',
        //   data: [36, 10,3],
        //   itemStyle:{
        //     barBorderRadius:10,
        //   },
        //   barMaxWidth: 12
        // },
        // {
        //   name: '检测到期数',
        //   type: 'bar',
        //   data: [36, 10,3],
        //   itemStyle:{
        //     barBorderRadius:10,
        //   },
        //   barMaxWidth: 12
        // },

      ]

      // 使用刚指定的配置项和数据显示图表。
      this.personEcharts.setOption(option);
    },
    getBasicInfo(id,areaId){
      let xAxis = []
      let overageCount = []
      let param ={}
      if(areaId){
        param = {orgaId:id,areaId:areaId}
      }else {
        param = {orgaId:id}
      }
      if(this.type ==1){
        // 局级
        screen.getOrgaEquipOverageStatic(param).then(res =>{
          res.data.forEach(item =>{
            xAxis.push(item.equipType)
            overageCount.push(item.overageCount)
          })
          this.getInfo(xAxis, overageCount)
        })

      }else if(this.type ==2){
        // 公司级
        screen.getCompEquipOverageStatic(param).then(res =>{
          res.data.forEach(item =>{
            xAxis.push(item.equipType)
            overageCount.push(item.overageCount)
          })
          this.getInfo(xAxis, overageCount)
        })
      }else {
        // 项目级
        screen.getProjEquipOverageStatic({orgaId:id}).then(res =>{
          res.data.forEach(item =>{
            xAxis.push(item.equipType)
            overageCount.push(item.overageCount)
          })
          this.getInfo(xAxis, overageCount)
        })
      }
    },
  }
}
</script>

<style scoped lang="less">
.person-char {
  width: 100%;
  height: 100%;
  color: #ffffff;
}

</style>