<template>
  <div class="screen-main">
    <screen-head></screen-head>
    <div class="screen-body">
      <div class="body-left body-box">
        <div class="screen-item bg-left">
          <div class="title left">项目信息</div>
          <div class="item-body">
            <project-tree @checked=checkedProject></project-tree>
          </div>
        </div>
        <div class="screen-item">
          <div class="comm-bg">
            <div class="title">设备使用情况</div>
            <div class="more-title">查看详情</div>
            <div class="item-body">
              <equipment-use :id="activeTree" :type="activeType" :area-id="areaId"></equipment-use>
            </div>
          </div>
        </div>
        <div class="screen-item">
          <div class="comm-bg">
            <div class="title">人员信息</div>
            <div class="item-body">
              <person-info :id="activeTree" :type="activeType" :area-id="areaId"></person-info>
            </div>
          </div>
        </div>
      </div>
      <div class="body-center">
        <div class="center-type">
          <center-total-list :id="activeTree" :type="activeType" :area-id="areaId"></center-total-list>
        </div>
        <div class="center-map">
          <center-map v-if="isMap" :id="activeTree" :type="activeType" @change="changeArea"></center-map>
          <center-table v-else :id="activeTree" :type="activeType"></center-table>
        </div>
      </div>
      <div class="body-right body-box">
        <div class="screen-item bg-right">
          <div class="title left">设备信息</div>
          <div class="item-body">
            <equipment-info :id="activeTree" :type="activeType" :area-id="areaId"></equipment-info>
          </div>
        </div>
        <div class="screen-item">
          <div class="comm-bg">
            <div class="title">设备月检</div>
            <div class="more-title">查看详情</div>
            <div class="item-body">
              <equipment-month :id="activeTree" :type="activeType" :area-id="areaId"></equipment-month>
            </div>
          </div>
        </div>
        <div class="screen-item">
          <div class="comm-bg">
            <div class="title">设备预警</div>
            <div class="more-title">查看详情</div>
            <div class="item-body">
              <equipment-alarm :id="activeTree" :type="activeType" :area-id="areaId"></equipment-alarm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ScreenHead from "../../../components/page/screen/ScreenHead";
import ProjectTree from "../../../components/page/screen/ProjectTree";
import EquipmentUse from "../../../components/page/screen/EquipmentUse";
import EquipmentInfo from "../../../components/page/screen/EquipmentInfo";
import EquipmentMonth from "../../../components/page/screen/EquipmentMonth";
import PersonInfo from "../../../components/page/screen/PersonInfo";
import EquipmentAlarm from "../../../components/page/screen/EquipmentAlarm";
import CenterTotalList from "../../../components/page/screen/CenterTotalList";
import CenterMap from "../../../components/page/screen/CenterMap";
import CenterTable from "../../../components/page/screen/CenterTable";
export default {
  name: "projectScreen",
  components: {
    CenterTable,
    CenterMap,
    CenterTotalList,
    EquipmentAlarm, PersonInfo, EquipmentMonth, EquipmentInfo, EquipmentUse, ProjectTree, ScreenHead},
  data(){
    return{
      isMap: true,
      activeTree: '',
      activeType:1,
      areaId: 0
    }
  },
  methods:{
    checkedProject(val){
      console.log(val)
      if(val.orgaPathIds){
        // orgaPathIds 组织机构树，例如：orgaPathIds=1-1-1， 3级的为项目，1，2级的为公司，没有的默认是公司
        // let orgaPath = val.orgaPathIds.split('-').map(item => Number(item))
        let orgaPath = Number(val.level) + 1
        this.activeType = orgaPath  // 1 局级  2公司级 3 项目级
        if(orgaPath >2){
          this.isMap = false
        }else {
          this.isMap = true
        }
      }else {
        this.isMap = true
      }
      // 当前的公司（项目）信息id，为后面的统计赋值id
      this.activeTree = val.value
    },
    changeArea(id){
      // 地图中的地区id发生变化
      this.areaId = id
    },
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/css/screen.less";

</style>