<template>
  <div class="person-char" ref="screenPersonRef"></div>
</template>

<script>
import * as echarts from 'echarts'
import {screenEchartOption} from '../../../config/screenEchartConf'
import screen from "../../../api/modules/screen";

export default {
  name: "PersonInfo",
  props:['id','type','areaId'],
  watch:{
    id(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(newVal,this.areaId)
      }
    },
    areaId(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(this.id,newVal)
      }
    }
  },
  data() {
    return {
      personEcharts: null
    }
  },
  mounted() {
    this.personEcharts = echarts.init(this.$refs.screenPersonRef, "chalk");
    if(this.id){
      this.getBasicInfo(this.id,this.areaId)
    }
  },
  methods: {
    getInfo(xAxis,operaNumArr,cetifiExpirNum) {
      let option = screenEchartOption
      option.legend.data = ['人员总数', '证件到期数']
      option.xAxis.data = xAxis
      option.series = [
        {
          name: '人员总数',
          type: 'bar',
          data: operaNumArr,
          itemStyle:{
            barBorderRadius:10,
          },
          barMaxWidth: 12
        },
        {
          name: '证件到期数',
          type: 'bar',
          data: cetifiExpirNum,
          itemStyle:{
            barBorderRadius:10,
          },
          barMaxWidth: 12
        },
      ]

      // 使用刚指定的配置项和数据显示图表。
      this.personEcharts.setOption(option);
    },
    getBasicInfo(id,areaId){
      let operaNumArr = []
      let cetifiExpirNum =[]
      let xAxis = []
      let param ={}
      if(areaId){
        param = {orgaId:id,areaId:areaId}
      }else {
        param = {orgaId:id}
      }
      if(this.type ==1){
        // 局级
        screen.getOrgaOperatorInfo(param).then(res =>{
            if(res.data && res.data.length > 0 ){
              res.data.forEach(item =>{
                xAxis.push(item.typeName);
                operaNumArr.push(item.operaNum)
                cetifiExpirNum.push(item.cetifiExpirNum)
              })
            }
          this.getInfo(xAxis,operaNumArr,cetifiExpirNum)
        })

      }else if(this.type ==2){
        // 公司级
        screen.getCompOperatorInfo(param).then(res =>{
          if(res.data && res.data.length > 0 ){
            res.data.forEach(item =>{
              xAxis.push(item.typeName);
              operaNumArr.push(item.operaNum)
              cetifiExpirNum.push(item.cetifiExpirNum)
            })
          }
          this.getInfo(xAxis,operaNumArr,cetifiExpirNum)
        })
      }else {
        // 项目级
        screen.getProjOperatorInfo({orgaId:id}).then(res =>{
          if(res.data && res.data.length > 0 ){
            res.data.forEach(item =>{
              xAxis.push(item.typeName);
              operaNumArr.push(item.operaNum)
              cetifiExpirNum.push(item.cetifiExpirNum)
            })
          }
          this.getInfo(xAxis,operaNumArr,cetifiExpirNum)
        })
      }
    },
  }
}
</script>

<style scoped lang="less">
.person-char {
  width: 100%;
  height: 100%;
  color: #ffffff;
}

</style>