<template>
  <div class="table-main" ref="screenMonthTable">
    <screen-table :data="useList" :height="height" v-if="height">
      <el-table-column
          align="center"
          prop="equipType"
          label="名称">
        <template slot-scope="scope">
          <span class="click-line" @click="goDetail(scope.row)">{{scope.row.equipType}}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="checkTotal"
          label="总数">
        <template slot-scope="scope">
          <span class="click-line" @click="goDetail(scope.row)">{{scope.row.checkTotal}}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="hasCheck"
          label="到期数">
        <template slot-scope="scope">
          <span class="click-line" @click="goDetail(scope.row)">{{scope.row.hasCheck}}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="completionRate"
          label="完成率">
        <template slot-scope="scope">
          <span class="click-line" @click="goDetail(scope.row)">{{scope.row.completionRate}}</span>
        </template>
      </el-table-column>
    </screen-table>
  </div>

</template>

<script>
import ScreenTable from "./ScreenTable";
import screen from "../../../api/modules/screen";

export default {
  name: "EquipmentMonth",
  components: {ScreenTable},
  props:['id','type','areaId'],
  watch:{
    id(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(newVal,this.areaId)
      }
    },
    areaId(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(this.id,newVal)
      }
    }
  },
  data() {
    return {
      height:null,
      useList: []
    }
  },
  created() {

  },
  mounted() {
    this.height= this.$refs.screenMonthTable.clientHeight - 2 + 'px'

  },
  methods:{
    getBasicInfo(id,areaId){
      let param ={}
      if(areaId){
        param = {orgaId:id,areaId:areaId}
      }else {
        param = {orgaId:id}
      }
      if(this.type ==1){
        // 局级
        screen.getOrgaMothCheckStatic(param).then(res =>{
          this.useList = res.data
        })
      }else if(this.type ==2){
        // 公司级
        screen.getCompMothCheckStatic(param).then(res =>{
          this.useList = res.data
        })
      }else {
        // 项目级
        screen.getProjMothCheckStatic({orgaId:id}).then(res =>{
          this.useList = res.data
        })
      }
    },
    goDetail(row){
      let query ={}
      if(row.orgaPathIds){
        query.orgaPathIds = row.orgaPathIds
      }
      this.$router.push({path:'/view/monthInspection', query:query})
    }
  }
}
</script>

<style scoped lang="less">
.table-main{
  height: 100%;
}


</style>