export const screenEchartOption = {
    color:['#3675F0','#E57414', '#F6B550', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],
    tooltip: {},
    legend: {
        textStyle: {
            color: "#ffffff",
            fontSize: 10,
        },
        right: 20,
        itemWidth:10,
        itemHeight:10,
    },
    xAxis: {
        axisLabel: {
            color: "#ffffff",
            fontSize: 10,
        },
        axisTick: {
            show: false
        },
        axisLine:{
            lineStyle:{
                color: '#0075EB'
            }
        },
        minorTick:{
            length: 3
        }
    },
    yAxis: {
        axisLabel: {
            color: "#ffffff",
            fontSize: 10,
        },
        splitLine:{
            lineStyle:{
                color: '#0075EB'
            }
        },
    },
};