<template>
  <div>
    <el-input
        size="mini"
        placeholder="请输入需要查看的公司"
        v-model="filterText">
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
    <div class="screen-tree-main">
      <el-tree :data="projectList"
               :props="defaultProps"
               node-key="value"
               :default-expanded-keys="[2, 3]"
               :filter-node-method="filterNode"
               ref="screenTree"
               @node-click="handleNodeClick">
      </el-tree>
    </div>

  </div>
</template>

<script>
import basic from "../../../api/modules/basic";
import {organizationCascader} from "../../../utils/tool";

export default {
  name: "ProjectTree",
  data() {
    return {
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      projectList: []
    }
  },
  watch: {
    filterText(val) {
      this.$refs.screenTree.filter(val);
    }
  },
  mounted() {
    // 获取项目树
    this.getCopanyInfo()
  },
  methods: {
    getCopanyInfo(){
      basic.getOrganizationTree({orgaPid:0}).then(res =>{
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data)
        this.projectList = data
        // 默认选择第一个
        this.$emit('checked', data[0])
      })
    },
    handleNodeClick(val) {
      this.$emit('checked', val)
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    }

  }
}
</script>

<style scoped lang="less">
.screen-tree-main {
  margin-top: 10px;
}

</style>