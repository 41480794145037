import api from '../httpServer'
import config from '../config'

const record = {
    /*
    * 进场验收
    * */
    // 添加设备信息
    addEquipment(data) {
        return api.post(`${config.headerUrl}device/AddEquipment`, data)
    },
    // 删除设备信息
    delEquipment(data) {
        return api.post(`${config.headerUrl}device/delEquipment`, data)
    },
    // 编辑设备信息
    updateEquipment(data) {
        return api.post(`${config.headerUrl}device/updateEquipment`, data)
    },
    // 获取设备详情
    getProjectById(data) {
        return api.get(`${config.headerUrl}device/GetProjectById`, data)
    },
    // 设备信息的分页查询
    getEquipmentListByPage(data) {
        return api.get(`${config.headerUrl}device/GetEquipmentListByPage`, data)
    },
    // 获取设备编号
    getEquipNo(data) {
        return api.get(`${config.headerUrl}device/GetEquipNo`, data)
    },
    // 设备绑定终端
    equipmentBind(data) {
        return api.post(`${config.headerUrl}device/equipmentBind`, data)
    },
    // 获取设备微信二维码
    getEquipWxCode(data) {
        return api.post(`${config.headerUrl}device/GetEquipWxCode`, data)
    },

    /*
    * 安拆台账
    * */
    // 添加安拆台账
    addDismant(data) {
        return api.post(`${config.headerUrl}device/AddDismant`, data)
    },
    // 编辑安拆台账
    editDismant(data) {
        return api.post(`${config.headerUrl}device/EditDismant`, data)
    },
    // 删除安拆台账
    deleteDismant(data) {
        return api.post(`${config.headerUrl}device/DeleteDismant`, data)
    },
    // 获取安拆详情
    getDismantById(data) {
        return api.get(`${config.headerUrl}device/GetDismantById`, data)
    },
    // 设备安拆台账列表查询
    getDismantList(data) {
        return api.get(`${config.headerUrl}device/GetDismantList`, data)
    },
    // 设备安拆台账分页表查询
    getDismantPage(data) {
        return api.get(`${config.headerUrl}device/GetDismantPage`, data)
    },

    /*
    * 人员
    * */
    // 设备人员添加
    addEquipmentOper(data) {
        return api.post(`${config.headerUrl}device/AddEquipmentOper`, data)
    },
    // 设备人员删除
    deleteEquipmentOper(data) {
        return api.post(`${config.headerUrl}device/DeleteEquipmentOper`, data)
    },
    // 查询设备人员列表
    getEquipmentOperList(data) {
        return api.get(`${config.headerUrl}device/GetEquipmentOperList`, data)
    },
    // 查询设备人员分页
    getEquipmentOperPage(data) {
        return api.get(`${config.headerUrl}device/GetEquipmentOperPage`, data)
    },
    /*
       * 操作人员自检项目
       * */
    // 新增信息
    addSelfCheckInfo(data) {
        return api.post(`${config.headerUrl}equipSelfcheckItem/addInfo`, data)
    },
    // 删除信息
    deleteSelfCheckInfo(data) {
        return api.post(`${config.headerUrl}equipSelfcheckItem/deleteInfo`, data)
    },
    // 修改信息
    editSelfCheckInfo(data) {
        return api.post(`${config.headerUrl}equipSelfcheckItem/editInfo`, data)
    },
    // 批量修改信息
    editSelfBatch(data) {
        return api.post(`${config.headerUrl}equipSelfcheckItem/editBatch`, data)
    },
    // 获取详情
    getSelfCheckInfo(data) {
        return api.get(`${config.headerUrl}equipSelfcheckItem/getDetail`, data)
    },
    // 获取列表
    getSelfCheckList(data) {
        return api.get(`${config.headerUrl}equipSelfcheckItem/getList`, data)
    },

    /*
     * 操作人员自检记录
     * */
    // 添加自检记录
    addSelfCheckRecord(data) {
        return api.post(`${config.headerUrl}selfCheck/AddSelfcheck`, data)
    },
    // 删除自检记录
    deleteSelfCheckRecord(data) {
        return api.post(`${config.headerUrl}selfCheck/DeleteSelfcheck`, data)
    },
    // 编辑自检记录
    editSelfCheckRecord(data) {
        return api.post(`${config.headerUrl}selfCheck/EditSelfcheck`, data)
    },
    // 自检-获取记录详情
    getSelfCheckRecordDetail(data) {
        return api.get(`${config.headerUrl}selfCheck/GetSelfcheckDetail`, data)
    },
    // 分页获取设备自检记录
    getSelfCheckRecordByPage(data) {
        return api.get(`${config.headerUrl}selfCheck/GetSelfcheckListByPage`, data)
    },
    // 获取操作员自检记录列表
    getSelfCheckRecordList(data) {
        return api.get(`${config.headerUrl}selfCheck/GetSelfcheckList`, data)
    },
    // 自检--获取自检统计
    getSeflCheckWarn(data) {
        return api.get(`${config.headerUrl}selfCheck/GetSeflCheckWarn`, data)
    },

    /*
    * 巡检记录
    * */
    // 添加巡检记录
    addInspection(data) {
        return api.post(`${config.headerUrl}onSiteInspection/AddInspection`, data)
    },
    // 删除巡检记录
    deleteInspection(data) {
        return api.post(`${config.headerUrl}onSiteInspection/DeleteInspection`, data)
    },
    // 编辑巡检记录
    editInspection(data) {
        return api.post(`${config.headerUrl}onSiteInspection/EditInspection`, data)
    },
    //巡检--获取详情
    getInspectionDetail(data) {
        return api.get(`${config.headerUrl}onSiteInspection/GetDetail`, data)
    },
    //巡检记录列表
    getInspectionList(data) {
        return api.get(`${config.headerUrl}onSiteInspection/GetInspectionList`, data)
    },
    // 设备巡检的分页查询
    getInspectionListByPage(data) {
        return api.get(`${config.headerUrl}onSiteInspection/GetInspectionListByPage`, data)
    },
    // 巡检--获取巡检统计
    getOnSiteWarn(data) {
        return api.get(`${config.headerUrl}onSiteInspection/GetWarn`, data)
    },
    /*
    * 设备月检
    * */
    // 添加月检记录
    addMonthInspection(data) {
        return api.post(`${config.headerUrl}monthCheck/AddMonthInspection`, data)
    },
    // 删除月检记录
    deleteMonthInspection(data) {
        return api.post(`${config.headerUrl}monthCheck/DeleteMonthInspection`, data)
    },
    // 编辑月检记录
    editMonthInspection(data) {
        return api.post(`${config.headerUrl}monthCheck/EditMonthInspection`, data)
    },
    // 获取设备月检记录列表
    getMonthInspectionList(data) {
        return api.get(`${config.headerUrl}monthCheck/GetMonthInspectionList`, data)
    },
    // 月检--获取记录详情
    getMonthInspectionDetail(data) {
        return api.get(`${config.headerUrl}monthCheck/GetMonthInspectionDetail`, data)
    },
    // 分页获取设备月检记录
    getMonthInspectionListByPage(data) {
        return api.get(`${config.headerUrl}monthCheck/GetListByPage`, data)
    },
    // 月检--获取月检统计
    getMonthWarn(data) {
        return api.get(`${config.headerUrl}monthCheck/GetMonthWarn`, data)
    },

    /*
   *  维保项目
   * */
    // 新增信息
    addMaintenanInfo(data) {
        return api.post(`${config.headerUrl}equipMaintenanItem/addInfo`, data)
    },
    // 批量修改信息
    addMaintenaneBatch(data) {
        return api.post(`${config.headerUrl}equipMaintenanItem/editBatch`, data)
    },
    // 删除信息
    deleteMaintenanInfo(data) {
        return api.post(`${config.headerUrl}equipMaintenanItem/deleteInfo`, data)
    },
    // 修改信息
    editMaintenanInfo(data) {
        return api.post(`${config.headerUrl}equipMaintenanItem/editInfo`, data)
    },
    // 获取详情
    getMaintenanDetail(data) {
        return api.get(`${config.headerUrl}equipMaintenanItem/getDetail`, data)
    },
    // 获取列表
    getMaintenanList(data) {
        return api.get(`${config.headerUrl}equipMaintenanItem/getList`, data)
    },
    /*
   *  维保记录
   * */
    // 新增一条维保
    addEquipMaintenan(data) {
        return api.post(`${config.headerUrl}deviceRecord/AddEquipMaintenan`, data)
    },
    // 修改一条维保
    updateEquipMaintenan(data) {
        return api.post(`${config.headerUrl}maintenan/editInfo`, data)
    },
    // 维保-删除记录
    deleteEquipMaintenan(data) {
        return api.post(`${config.headerUrl}maintenan/deleteInfo`, data)
    },
    // 获取设备维保台账详情
    getEquipMaintenanDetail(data) {
        return api.get(`${config.headerUrl}deviceRecord/GetEquipMaintenanDetail`, data)
    },
    //分页获取设备维保记录
    getMaintenanListByPage(data) {
        return api.get(`${config.headerUrl}deviceRecord/GetMaintenanListByPage`, data)
    },

    /*
    * 整改记录
    * */
    // 整改-分页获取设备整改记录
    getRectificationListByPage(data) {
        return api.get(`${config.headerUrl}deviceRecord/GetRectificationListByPage`, data)
    },
    // 查看整改单详情
    getRectficaDetails(data) {
        return api.get(`${config.headerUrl}deviceRecord/getRectficaDetails`, data)
    },
    /*
    * 告警台账
    * */
    // 分页获取设备告警台账
    getAlarmPage(data) {
        return api.get(`${config.headerUrl}deviceRecord/getAlarmPage`, data)
    },
    /*
    * 上传附件
    * */
    addRecordDismant(data) {
        return api.post(`${config.headerUrl}deviceRecord/AddDismant`, data)
    },
    /*
    * 巡检、月检项目列表获取
    * */
    getEquipTypeItemList(data) {
        return api.get(`${config.headerUrl}equipTypeItem/getList`, data)
    },


}

export default record