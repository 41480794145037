<template>
  <div class="screen-head">
    <div class="head-middle-title">科达光电会议签到管理系统</div>
    <div class="head-middle-subtitle">全生命周期管理系统</div>
    <div class="name-time-box">
      <div class="name">欢迎你：{{ user.staffName }}</div>
      <clocks></clocks>
    </div>
    <div class="head-right">
      <div class="right-item border">
        <i class="project-icon"></i>
<!--        <span class="project-title">沪渝蓉项目</span>-->
<!--        <el-select v-model="nowProject" siz="small"  placeholder="请选择" popper-class="screenSelect">-->
<!--          <el-option-->
<!--              v-for="item in projectList"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
      </div>
      <div class="right-item border">
        <img src="../../../assets/images/screen/head-project-message.png">
      </div>
      <div class="right-item" @click="goHome">
        <img src="../../../assets/images/screen/head-cmd.png">
        <span class="title">管理系统</span>
      </div>
    </div>
  </div>
</template>

<script>
import Clocks from "../../layout/header/Clocks";
import {mapState} from "vuex";
export default {
  name: "ScreenHead",
  components: {Clocks},
  computed: {
    // 通过扩展运算符映射Vuex全局的左侧权限菜单
    ...mapState({
      staffRole: state => state.common.staffRole,
      user: state => state.common.user
    })
  },
  data(){
    return{
      nowProject:1,
      projectList:[
        {label:'沪渝蓉项目', value:1},
        {label:'沪渝项目', value:2},
      ]
    }
  },
  methods:{
    goHome(){
      // 获取权限路径的最开始的那一级目录，目录配置最好配置redirect
      let firstPath = this.staffRole[0].children && this.staffRole[0].children.length > 0
          ? this.staffRole[0].children[0].path
          :this.staffRole[0].path
      // this.$router.push({path:'/view/basic/company'})
      this.$router.push({path:firstPath})
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/css/screen.less";

</style>