<template>
  <div class="map-main" @dblclick="revertMap">
    <div class="chart" ref="map_ref"></div>
  </div>

</template>

<script>
import axios from "axios";
import * as echarts from 'echarts'
import basic from "../../../api/modules/basic";
import {getProvinceMapInfo} from '../../../utils/map_utils'

export default {
  name: "CenterMap",
  props:['id','type'],
  watch: {
    id(newVal,oldVal){
      if(newVal != oldVal){
        this.getBasicInfo(newVal)
      }
    }
  },
  data(){
    return{
      mapChart:null,
      allData:[],
    }
  },
  mounted() {
    this.mapChart = echarts.init(this.$refs.map_ref, "chalk");
    echarts.registerMap("china", require("/public/map/china.json"));
    this.init()
  },
  methods:{
    init(){
      const initOption = {
        // 图表标题
        title: {
          // 标题距离左边和顶部设置部分距离
          left: 20,
          top: 20,
          textStyle: {
            color: "#fff",
          },
        },
        // 提示组件
        tooltip: {
          // 鼠标移到图里面的浮动提示框
          show: true,
        },
        // 指明图表类型为地图
        geo: {
          type: "map",
          map: "china",
          // 地图距离上边和下边距离，并且放大一点点
          top: "5%",
          bottom: "5%",
          label: {
            show: true, // 展示标签
            color: "#fff",
            emphasis: {
              show: true,
              color: "#fff",
            },
          },
          zoom: 1.05, // 设置初始化的缩放比例
          // center: [87.617733, 43.792818], // 设置地图中心点的坐标
          roam: true, //设置允许缩放以及拖动的效果
          // 设置图表风格样式，每个省份的背景色以及边框线色
          itemStyle: {
            areaColor: "rgba(20, 41, 87,0.7)",
            // borderColor: "#333",
            borderColor: "#0f6ae9",
            borderWidth: 1.5,
            // 鼠标浮入高亮颜色
            emphasis: {
              areaColor: "#2ab8ff",
            },
          },
        },
        // 图例组件呈左下角展示
        legend: {
          show: false, //取消显示
        },
      };
      // 把配置项给实例对象--->chartInstance
      this.mapChart.setOption(initOption);
      this.mapChart.on("click", async (item) => {
        // 获取地图点击的省的名称
        let name = item.name
       // 后端接口返回 所属省份的id，给大屏中其他统计赋值
        let res =  await basic.getAreaList({name:name})
        let earId = res.data[0].id
        this.$emit('change', earId)

        // 获取地图的信息，展示某个省份的地图
        const provinceInfo = getProvinceMapInfo(item.name);
        // 这里点击一些省份或者点击进去后的小地图会报错404，
        // 因为静态资源还没有对应的json文件，所以判断
        if (typeof provinceInfo.key === "undefined") {
          this.mapData = {}; // 释放资源，因为有未定义的详细小地图数据
          return;
        }
        let result = await axios.get(provinceInfo.path)
        // 需要获取这个省份的地图矢量数据
        echarts.registerMap(provinceInfo.key, result.data);
        // 指定配置项和数据源，切换地图的显示，展示点击后的省份更详细地图展示
        const changeOption = {
          geo: {
            map: provinceInfo.key, // 地图根据key键展示对应的详细小地图
          },
        };
        // 把配置项给实例对象--->chartInstance
        this.mapChart.setOption(changeOption);
      });
    },
    updateChart(){
      let data = this.allData.map((item) => {
        // return的这个对象就代表的是一个类别下的所有散点数据
        // 如果想在地图中显示散点的数据, 我们需要给散点的图表增加一个配置,
        return {
          name: item.projName,
          value: [item.projLong, item.projLat,''],
        }
      });
      const seriesArr = {
        type: "effectScatter", // 涟漪效果
        coordinateSystem: "geo", // 指明散点使用的坐标系统  geo的坐标系统
        // 让涟漪效果更夸张，像空心的波纹一样变化效果
        rippleEffect: {
          scale: 3, // 设置涟漪动画的缩放比例
          brushType: "stroke",
        },
        name: '',
        // 每种类别下都有children，也就是对应的数据集，所以这里用children
        // data: item.children,
        data: data,
        encode: {
          value: 3
        },
        // 风格样式
        itemStyle: {
          // shadowBlur: 5,
          shadowColor: "rgb(82, 166, 235)",
          color: "#03E9F4",
        },
      };
      // 指定配置项和数据源
      const option = {
        // 图例组件
        // 数据源
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name;
          },
          textStyle: {
            fontSize: 12
          }
        },
        series: seriesArr,
      };
      // 把配置项给实例对象--->chartInstance
      this.mapChart.setOption(option);
    },
    getBasicInfo(id){
      if(this.type ==1){
        // 局级  获取项目信息
        basic.getProjectList({orgaPathIds:id}).then(res =>{
          this.allData = res.data
          this.updateChart()
        })

      }else if(this.type ==2){
        // 公司级 获取项目信息
        basic.getProjectList({orgaId:id}).then(res =>{
          this.allData = res.data
          this.updateChart()
        })
      }
    },
    revertMap() {
      // 双击返回全国地图 指定配置项和数据源
      const revertOption = {
        geo: {
          map: "china", // 地图展示中国地图
        },
      };
      // 把配置项给实例对象--->chartInstance
      this.mapChart.setOption(revertOption);
      this.$emit('change', 0)
    },
  }
}
</script>

<style scoped lang="less">
.map-main, .chart{
  width: 100%;
  height: 100%;
}

</style>